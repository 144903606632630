import { IOrganizationBillingTime, OutOfStockBehavior } from "@smartrr/shared/entities/Organization";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";

import {
  GeneralSmartrrDispatch,
  SmartrrAction,
  SmartrrThunkAction,
} from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const updateOrgBillingTime =
  ({
    orgBillingTime,
  }: {
    orgBillingTime: IOrganizationBillingTime;
  }): SmartrrThunkAction<"UPDATING_BILLING_TIME" | "ERROR_UPDATING_BILLING_TIME" | "UPDATED_BILLING_TIME"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/billing-time", {
        reqBody: {
          orgBillingTimeConfig: orgBillingTime,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_BILLING_TIME",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_BILLING_TIME",
          payload: {
            errorMessage: failure.message,
          },
        }),
      updatedOrganization =>
        dispatch({
          type: "UPDATED_BILLING_TIME",
          payload: {
            updatedOrganization,
          },
        })
    );

export const updateOrgBillingRetryConfig =
  (numBillingRetriesConfig: {
    numBillingRetries?: number;
    delayBetweenRetries?: number;
    numFailedCyclesBeforeCancel?: number;
  }): SmartrrThunkAction<
    "UPDATING_BILLING_RETRIES_CONFIG" | "ERROR_UPDATING_BILLING_RETRIES_CONFIG" | "UPDATED_BILLING_RETRIES_CONFIG"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/billing-retry-config", {
        reqBody: {
          numBillingRetriesConfig,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_BILLING_RETRIES_CONFIG",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_BILLING_RETRIES_CONFIG",
          payload: {
            errorMessage: failure.message,
          },
        }),
      updatedOrganization =>
        dispatch({
          type: "UPDATED_BILLING_RETRIES_CONFIG",
          payload: {
            updatedOrganization,
          },
        })
    );

export const updateOrgConfirmationDaysConfig =
  (billingConfirmationWaitingDays: {
    billingConfirmationWaitingDays?: number;
  }): SmartrrThunkAction<
    | "UPDATING_CONFIRMATION_DAYS_CONFIG"
    | "ERROR_UPDATING_CONFIRMATION_DAYS_CONFIG"
    | "UPDATED_CONFIRMATION_DAYS_CONFIG"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/confirmation-days-config", {
        reqBody: {
          billingConfirmationWaitingDays,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_CONFIRMATION_DAYS_CONFIG",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_CONFIRMATION_DAYS_CONFIG",
          payload: {
            errorMessage: failure.message,
          },
        }),
      updatedOrganization =>
        dispatch({
          type: "UPDATED_CONFIRMATION_DAYS_CONFIG",
          payload: {
            updatedOrganization,
          },
        })
    );

export const updateOrgOoSBehavior =
  (
    outOfStockBehavior: OutOfStockBehavior
  ): SmartrrThunkAction<
    "UPDATING_OUT_OF_STOCK_BEHAVIOR" | "ERROR_UPDATING_OUT_OF_STOCK_BEHAVIOR" | "UPDATED_OUT_OF_STOCK_BEHAVIOR"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/out-of-stock-config", {
        reqBody: {
          outOfStockBehavior,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_OUT_OF_STOCK_BEHAVIOR",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_OUT_OF_STOCK_BEHAVIOR",
          payload: {
            errorMessage: failure.message,
          },
        }),
      updatedOrganization =>
        dispatch({
          type: "UPDATED_OUT_OF_STOCK_BEHAVIOR",
          payload: {
            updatedOrganization,
          },
        })
    );

function createSelectOrganizationAction(
  orgId: string,
  user: IVendorUser
): SmartrrAction<"SELECT_ACTIVE_ORGANIZATION"> {
  return {
    type: "SELECT_ACTIVE_ORGANIZATION",
    payload: {
      orgId,
      user,
    },
  };
}

export const loadIntegrations =
  (): SmartrrThunkAction<"LOADING_INTEGRATIONS" | "ERROR_LOADING_INTEGRATIONS" | "LOADED_INTEGRATIONS"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/integrations"),
      () =>
        dispatch({
          type: "LOADING_INTEGRATIONS",
          payload: undefined,
        }),
      error =>
        dispatch({
          type: "ERROR_LOADING_INTEGRATIONS",
          payload: {
            errorMessage: error.message,
          },
        }),
      integrations =>
        dispatch({
          type: "LOADED_INTEGRATIONS",
          payload: {
            integrations,
          },
        })
    );

export const loadAllOrgs = async (dispatch: GeneralSmartrrDispatch) => {
  const result = await typedFrontendVendorApi.getReq("/organizations");
  if (result.type === "success") {
    dispatch({
      type: "LOAD_ADDITIONAL_ORGANIZATIONS",
      payload: {
        organizations: result.body,
      },
    });
  }
};

export const updateSelectedBillingDays =
  (
    selectedBillingDays: string[]
  ): SmartrrThunkAction<
    "UPDATE_SELECTED_BILLING_DAYS" | "ERROR_UPDATING_SELECTED_BILLING_DAYS" | "UPDATED_SELECTED_BILLING_DAYS"
  > =>
  async dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/setup/billingDays", {
        reqBody: {
          billingDays: selectedBillingDays,
        },
      }),
      () =>
        dispatch({
          type: "UPDATE_SELECTED_BILLING_DAYS",
          payload: undefined,
        }),
      failure => {
        return dispatch({
          type: "ERROR_UPDATING_SELECTED_BILLING_DAYS",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      setup => {
        return dispatch({
          type: "UPDATED_SELECTED_BILLING_DAYS",
          payload: {
            setup,
          },
        });
      }
    );

export const updateRetentionToggleForOrg =
  (
    hasRetentionCancelReasonEnabled: boolean,
    hasRetentionPauseReasonEnabled: boolean
  ): SmartrrThunkAction<
    "UPDATING_RETENTION_TOGGLE" | "ERROR_UPDATING_RETENTION_TOGGLE" | "UPDATED_RETENTION_TOGGLE"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/retention-toggle", {
        reqBody: {
          hasRetentionCancelReasonEnabled,
          hasRetentionPauseReasonEnabled,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_RETENTION_TOGGLE",
          payload: undefined,
        }),
      error =>
        dispatch({
          type: "ERROR_UPDATING_RETENTION_TOGGLE",
          payload: {
            errorMessage: error.message,
          },
        }),
      () =>
        dispatch({
          type: "UPDATED_RETENTION_TOGGLE",
          payload: {
            hasRetentionCancelReasonEnabled,
            hasRetentionPauseReasonEnabled,
          },
        })
    );

export const updateOrgShippingSettings =
  (): SmartrrThunkAction<
    "UPDATING_SHIPPING_SETTINGS" | "ERROR_UPDATING_SHIPPING_SETTINGS" | "UPDATED_SHIPPING_SETTINGS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/shipping-settings/update"),
      () =>
        dispatch({
          type: "UPDATING_SHIPPING_SETTINGS",
          payload: undefined,
        }),
      error =>
        dispatch({
          type: "ERROR_UPDATING_SHIPPING_SETTINGS",
          payload: {
            errorMessage: error.message,
          },
        }),
      organization =>
        dispatch({
          type: "UPDATED_SHIPPING_SETTINGS",
          payload: {
            updatedOrganization: organization,
          },
        })
    );
